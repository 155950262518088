<!--AI+物联网故障历史-->
<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="电梯救援码" prop="liftrescue">
              <a-input v-model.trim="queryParams.liftrescue" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="安装地址" prop="adress">
              <a-input v-model.trim="queryParams.adress" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="区域" prop="areacode">
              <a-cascader :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="queryAreaSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :change-on-select="true"></a-cascader>
            </a-form-model-item>
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='1'" label="使用单位" prop="userdepname">
              <a-input v-model="queryParams.userdepname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维保单位" prop="maintenancedepname">
              <a-input v-model="queryParams.maintenancedepname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="故障时间" prop="installendtime">
              <a-range-picker v-model="queryInstallDate"></a-range-picker>
            </a-form-model-item>
            <a-form-model-item v-if="this.pageType==='event'" label="AI事件类型" prop="eventcode">
              <a-select v-model="eventcode">
                <a-select-option v-for="(item, index) in eventOptions" :key="index" :value="item.eventcode">{{item.eventname}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item v-if="this.pageType==='elevator'" label="电梯事件类型" prop="eventcode">
              <a-select v-model="eventcode">
                <a-select-option v-for="(item, index) in lifteventOptions" :key="index" :value="item.eventcode">{{item.eventname}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item v-if="this.pageType==='alarm'" label="报警类型" prop="eventcode">
              <a-select v-model="eventcode" placeholder="请选择故障类型">
                <a-select-option v-for="(item, index) in alarmOptions" :key="index" :value="item.eventcode">{{item.eventname}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item v-if="this.pageType==='malfunction'" label="故障类型" prop="eventcode">
              <a-select v-model="eventcode" placeholder="请选择故障类型">
                <a-select-option v-for="(item, index) in faultOptions" :key="index" :value="item.eventcode">{{item.eventname}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="处置时长" prop="timerange">
              <a-select v-model="queryParams.timerange" placeholder="请选择故障类型">
                <a-select-option :value="10">{{"10分钟以内"}}</a-select-option>
                <a-select-option :value="600">{{"10分钟~30分钟"}}</a-select-option>
                <a-select-option :value="1800">{{"30分钟~2小时"}}</a-select-option>
                <a-select-option :value="7200">{{"超过2小时"}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-button type="primary" @click="deleteBatch()">删除</a-button>
            <a-button @click="exportBatch()" style="margin-left: 10px">导出</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :row-selection="{onChange: rowSelectChange}" :columns="tableColumns" :data-source="tableData" row-key="eventrecordid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="eventstatus" slot-scope="value">
            <a-tag :color="eventstatusColors[value]||''">{{eventstatus[value]||''}}</a-tag>
          </span>
          <span slot="eventname" :title="value?(value+(record.thirdeventname?('('+record.thirdeventname+')'):'')):''" slot-scope="value, record">{{value?(value+(record.thirdeventname?('('+record.thirdeventname+')'):'')):''}}</span>
          <span slot="starttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="endtime" slot-scope="value"><a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip></span>
          <span slot="check" slot-scope="value, record">
            <a-button type="link" size="small" @click="checkCalllog(value,record)">回放</a-button>
          </span>
          <span slot="btn" slot-scope="value, record">
            <a-button type="link" size="small" @click="handleAscend(value,record)">追溯</a-button>
          </span>
          <span slot="lookImg" slot-scope="value, record">
            <a-button type="link" size="small" @click="lookImage(value, record)">图片</a-button>
          </span>
          <span slot="timerange" :title="record.timerange?record.timerange+'分钟':''" slot-scope="value, record">{{record.timerange?record.timerange+'分钟':''}}</span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'watchline-'+record.eventrecordid">实时监控</a-menu-item>
                <a-menu-item :key="'delete-'+record.eventrecordid">删除</a-menu-item>
                <a-menu-item v-if="record.eventcode=='06'&&record.eventtype==3&&record.isturned!='1'" :key="'transform-'+record.eventrecordid">转化为困人</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-quick-jumper show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <traceBack :visible.sync="traceVisible" ref="trace" :StatusData="statusData"></traceBack>
    <checkTraceBack :visible.sync="checkTraceVisible" ref="checkTrace" :Datadetail="detaildata"></checkTraceBack>
    <imageBack :visible.sync="imageBackVisible" ref="imageBack" :EventData="eventData"></imageBack>
  </page-container>
</template>
<script>
import { getItemFromArrayByKey, getTimeRange } from 'U'
import store from '@/store'
import { mapState } from 'vuex'
import moment from 'moment'
import traceBack from './traceBack'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import approvestatusMap from '@/json/approvestatus'
import approvestatuscolors from '@/json/approvestatuscolors'
import {titleLists,eventTypes,eventstatus,eventstatusColors} from '@/json/wlwhistory'
import { getMentenacegroupListByCondition } from 'A/xtpz.js'
import {
  getEventrecordListByCondition,
  deleteEventrecord,
  getEventListByCondition, modifyEventrecord, exportEventrecordExcel
} from 'A/wlw.js'
import checkTraceBack from "V/wlwbj/main/checkTraceBack";
import imageBack from "V/jcgn/elevatorArchives/timeMonitoring/imageBack";
import {getLiftinfoById, getLiftinfoListByCondition} from "A/jcgn";
import {addEventrecord} from "A/thjlcx";
export default {
  name: 'wlwBasicPart',
  mixins: [areaselect, deptselect, pagination],
  components: {
    traceBack,
    checkTraceBack,
    imageBack
  },
  props: ['pageType'],
  data() {
    return {
      moment,
      imageBackVisible:false,
      eventData:'',
      detaildata:'',
      statusData:'',
      ipnetnum:'',
      eventstatus,
      eventstatusColors,
      approvestatusMap,
      approvestatuscolors,
      showAdvanced: false,
      queryParams: {
        liftnum: '',
        liftrescue: '',
        userdepid: '',
        maintenancedepid: '',
        maintenancegroupid: '',
        userdepname:'',
        maintenancedepname:'',
        adress: '',
        areacode: '',
        starttime: '',
        endtime: '',
        timerange:'',
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      maintenancedepidCascaderSelected: [],
      maintenancegroupidOptions: [],
      faultOptions:[],
      eventOptions:[],
      lifteventOptions:[],
      alarmOptions:[],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      operationLiftid: '',
      // checkModalVisible: false,
      checklistModalVisible: false,
      examineModalVisible: false,
      examinelistModalVisible: false,
      traceVisible:false,
      checkTraceVisible:false,
      batchrowids: '',
      begintime:'',
      endtime:'',
      eventcode:'',
      activeKey:'',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    eventtype(){
      return eventTypes[this.pageType]
    },
    pageTitle() {
      return titleLists[this.pageType]
    },
    tableColumns(){
      return [
        {
          title: '电梯救援码',
          dataIndex: 'liftrescue',
          key: 'liftrescue',
          ellipsis: true,
          width: 80,
        },
        {
          title: '电梯注册代码',
          dataIndex: 'liftnum',
          key: 'liftnum',
          ellipsis: true,
        },
        {
          title: '安装地址',
          dataIndex: 'adress',
          key: 'adress',
          ellipsis: true,
        },
        ...(this.pageType==='malfunction')?[{
          title: '故障名称',
          dataIndex: 'eventname',
          key: 'eventname',
          ellipsis: true,
          scopedSlots: { customRender: 'eventname' }
        }]:[],
        ...(this.pageType==='event'||this.pageType==='elevator'||this.pageType==='ternimal')?[{
          title: '事件名称',
          dataIndex: 'eventname',
          key: 'eventname',
          ellipsis: true,
          scopedSlots: { customRender: 'eventname' }
        }]:[],
        ...this.pageType==='alarm'?[{
          title: '报警名称',
          dataIndex: 'eventname',
          key: 'eventname',
          ellipsis: true,
          width: 120,
          scopedSlots: { customRender: 'eventname' }
        }]:[],
        {
          title: '使用单位',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '维保单位',
          dataIndex: 'maintenancedepname',
          key: 'maintenancedepname',
          ellipsis: true,
        },
        ...this.pageType==='malfunction'?[
        {
          title: '故障时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          ellipsis: true,
          scopedSlots: { customRender: 'starttime' }
        },
        {
          title: '故障解除时间',
          dataIndex: 'eventendtime',
          key: 'eventendtime',
          ellipsis: true,
          scopedSlots: { customRender: 'endtime' }
        },
        {
          title: '处置时长',
          key: 'timerange',
          align: 'timerange',
          width: 100,
          scopedSlots: { customRender: 'timerange' }
        },
        {
          title: '处理状态',
          dataIndex: 'eventstatus',
          key: 'eventstatus',
          ellipsis: true,
          width:70,
          scopedSlots: { customRender: 'eventstatus' }
        }]:[],
        ...this.pageType==='alarm'?[{
          title: '报警时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          ellipsis: true,
          scopedSlots: { customRender: 'starttime' }
        },{
          title: '故障解除时间',
          dataIndex: 'eventendtime',
          key: 'eventendtime',
          ellipsis: true,
          scopedSlots: { customRender: 'endtime' }
        },
        {
          title: '处置时长',
          key: 'timerange',
          align: 'timerange',
          width: 100,
          scopedSlots: { customRender: 'timerange' }
        },
        {
          title: '处理状态',
          dataIndex: 'eventstatus',
          key: 'eventstatus',
          ellipsis: true,
          width:70,
          scopedSlots: { customRender: 'eventstatus' }
        },
          {
            title: '通话记录',
            dataIndex: 'check',
            width:70,
            align: 'center',
            scopedSlots: { customRender: 'check' }
          }]:[],
        ...(this.pageType==='event'||this.pageType==='elevator'||this.pageType==='terminal')?[{
          title: '事件时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          ellipsis: true,
          scopedSlots: { customRender: 'starttime' }
        },{
          title: '事件解除时间',
          dataIndex: 'eventendtime',
          key: 'eventendtime',
          ellipsis: true,
          scopedSlots: { customRender: 'endtime' }
        },
        {
          title: '处置时长',
          key: 'timerange',
          align: 'timerange',
          width: 100,
          scopedSlots: { customRender: 'timerange' }
        },
        {
          title: '处理状态',
          dataIndex: 'eventstatus',
          key: 'eventstatus',
          ellipsis: true,
          width:70,
          scopedSlots: { customRender: 'eventstatus' }
        }]:[],
        {
          title: '运行历史',
          dataIndex: 'maintenancegroupname',
          key: 'maintenancegroupname',
          width:70,
          ellipsis: true,
          scopedSlots: { customRender: 'btn' }
        },
        {
          title: '图片',
          key: 'lookImg',
          align: 'center',
          width: 50,
          scopedSlots: { customRender: 'lookImg' }
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 50,
          scopedSlots: { customRender: 'operation' }
        },
      ]
    },
    breadcrumb() {
      const pages = [{name:titleLists.main,path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch: {
    queryAreaSelected(val) {
      if(val && val.length) {
        this.queryParams.areacode = val[val.length-1];
      }else {
        this.queryParams.areacode = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
      }else {
        this.queryParams.userdepid = '';
      }
    },
    maintenancedepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.maintenancedepid = val[val.length-1];
        this.initMaintenancegroupidOptions();
      }else {
        this.queryParams.maintenancedepid = '';
        this.maintenancegroupidOptions = [];
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    const keepAlivePages = ['elevatorArchivesDetail', 'timeMonitoring'];
    if(!toTab || keepAlivePages.indexOf(from.name)<0) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
    this.initFaultOptions();
    this.initEventOptions();
    this.initLiftEventOptions();
    this.initAlarmOptions();
  },
  methods: {
    init() {
      // this.deleteNullData();
      this.getTableData();
    },
    initFaultOptions(){
      let params={
        eventtype:'1'
      }
      getEventListByCondition(params).then(res=>{
        if (res && res.returncode == '0') {
          this.faultOptions = res.item;
          this.faultOptions.unshift({eventcode:"",eventname:"请选择"})
        }
      })
    },
    initEventOptions(){
      let params={
        eventtype:'3'
      }
      getEventListByCondition(params).then(res=>{
        if (res && res.returncode == '0') {
          this.eventOptions = res.item;
          this.eventOptions.unshift({eventcode:"",eventname:"请选择"})
        }
      })
    },
    initLiftEventOptions(){
      let params={
        eventtype:'4'
      }
      getEventListByCondition(params).then(res=>{
        if (res && res.returncode == '0') {
          this.lifteventOptions = res.item;
          this.lifteventOptions.unshift({eventcode:"",eventname:"请选择"})
        }
      })
    },
    initAlarmOptions(){
      let params={
        eventtype:'2'
      }
      getEventListByCondition(params).then(res=>{
        if (res && res.returncode == '0') {
          this.alarmOptions = res.item;
          this.alarmOptions.unshift({eventcode:"",eventname:"请选择"})
        }
      })
    },
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.queryInstallDate = null;
      this.userdepidCascaderSelected = [];
      this.maintenancedepidCascaderSelected = [];
      this.eventcode='';
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        eventtype:this.eventtype,
        eventcode:this.eventcode,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getEventrecordListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    checkCalllog(value,record){
      this.$refs.checkTrace.queryParams.caller = record.ipnetnum
      this.detaildata=record;
      this.checkTraceVisible = true
    },
    handleAscend(value,record){
      this.$refs.trace.queryParams.devicenum = record.devicenum
      this.$refs.trace.queryParams.eventrecordid = record.eventrecordid
      this.statusData=record;
      this.traceVisible = true
    },
    lookImage(value,record){
      this.eventData=record;
      this.imageBackVisible=true;
    },
    recover(value,record){
      if(record.eventendtime==''||record.eventendtime==null){
        this.eventendtime=moment(new Date()).format('YYYYMMDDHHmmss');
          this.begintime=record.eventstarttime;
          this.endtime=this.eventendtime;
      }else{
        this.begintime=record.eventstarttime;
        this.endtime=record.eventendtime;
      }
      if(record.eventtype==1){
        this.activeKey='tab2'
      }else if(record.eventtype==3){
        this.activeKey='tab3'
      } else if(record.eventtype==2){
        this.activeKey='tab4'
      }else if(record.eventtype==4){
        this.activeKey='tab5'
      }
      let params = {
        liftnum: record.liftnum
      };
      getLiftinfoListByCondition(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.showLoading();
          let params = {
            liftid:res.item[0].liftid
          }
          getLiftinfoById(params).then(res => {
            this.hideLoading();
            if(res && res.returncode == '0') {
              if(res.item.deviceinfos && res.item.deviceinfos.length) {
                let prePages = [
                  {
                    name: '实时监控',
                    path: ''
                  }
                ];
                this.$router.push({
                  name: 'timeMonitoring',
                  params: {
                    liftid: res.item.liftid,
                    liftnum: res.item.liftnum,
                    liftcode: res.item.liftcode,
                    deviceinfos: res.item.deviceinfos,
                    prePages: prePages,
                    begintime:this.begintime,
                    endtime:this.endtime,
                    activeKey:this.activeKey
                  }
                })
              }else {
                this.$message.error('该电梯无物联网设备');
              }
            }
          })
        }
      })
    },
    deleteConfirm(value, record) {
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.eventrecordid);
        },
        onCancel() {},
      });
    },
    delete(eventrecordid) {
      this.showLoading();
      if(eventrecordid) {
        let params = {
          eventrecordid
        };
        deleteEventrecord(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      if(type == 'detail') {
        let prePages = [
          {
            name: '基础功能',
            path: ''
          }
        ];
        if(this.pageType == 'draft') {
          prePages.push({
            name: '电梯草稿',
            path: 'elevatorDraft'
          })
        }else {
          prePages.push({
            name: '电梯档案',
            path: 'elevatorArchives'
          })
        }
        this.$router.push({
          name: 'elevatorArchivesDetail',
          params: {
            liftid: record.liftid,
            prePages: prePages
          }
        })
      }else {
        this.modalShowType = type;
        if(type == 'add') {
          this.modalDetailData = null;
        }else {
          this.modalDetailData = record;
        }
        this.modalVisible = true;
      }
    },
    initMaintenancegroupidOptions() {
      let params = {
        maintenancedepid: this.queryParams.maintenancedepid,
      }
      getMentenacegroupListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.maintenancegroupidOptions = res.item;
        }
      })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let eventrecordid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'eventrecordid', eventrecordid);
      if(type == 'delete') {
        this.deleteConfirm(eventrecordid, record)
      }else if(type == 'watchline') {
        this.recover(eventrecordid,record)
      }else if(type == 'transform'){
        this.transform(eventrecordid,record)
      }
    },
    transform(eventrecordid,record){
      let params={
        devicenum:record.devicenum,
        eventcode:'100',
        eventtype:1,
        eventstarttime:record.eventstarttime,
        eventendtime:record.eventendtime,
        eventstatus: 2,
        isturned:'1',
        month:moment(record.eventstarttime,'YYYYMMDDHHmmss').format("YYYY-MM"),
      }
      addEventrecord(params).then(res=>{
        if(res&&res.returncode=='0'){
          modifyEventrecord({eventrecordid:record.eventrecordid,devicenum:record.devicenum,eventcode:record.eventcode,eventtype:record.eventtype,isturned:'1'}).then(res=>{
            if(res&&res.returncode=='0'){
              this.$message.success("转化成功")
              this.getTableData()
            }
          })
        }
      })
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm({
        // title: '',
        content: '确定要删除选中数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(this.batchrowids);
        },
        onCancel() {},
      });
    },
    exportBatch(){
      this.$confirm({
        content: '确定要导出选中数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.exportBatchConfirm()
        },
        onCancel() {},
      });
    },
    exportBatchConfirm(){
      let params={
        ...this.queryParams,
        querytype:this.eventtype,
        eventtype:this.eventtype,
        eventcode:this.eventcode,
      }
      this.showLoading();
      exportEventrecordExcel(params).then(()=>{
        this.hideLoading();
      }).catch(()=>{
        this.hideLoading();
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>